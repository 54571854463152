import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import Content, { HTMLContent } from '../components/Content'
import PageJumbotron from '../components/PageJumbotron'
import * as ROUTES from '../constants/routes'

// eslint-disable-next-line
export const AboutPageTemplate = ({
  title,
  subheading,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content

  return (
    <Fragment>
      <PageHeader title={title} subheading={subheading} />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <h1 className="title is-size-2 is-uppercase has-text-weight-bold has-text-primary-dark">
                  About 99 Sense
                </h1>
                <h2 className="subtitle has-text-weight-bold mb-4">
                  99Sense creates an economic ecosystem with no geographical
                  boundaries for Service providers.
                </h2>
                <p>
                  <Link
                    className="button is-info is-rounded"
                    to={ROUTES.SIGN_UP}
                  >
                    Sign Up
                  </Link>
                </p>
                <PageContent className="content" content={content} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <PageJumbotron />
    </Fragment>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        subheading={post.frontmatter.subheading}
        content={post.html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subheading
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
